import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import axios from "axios";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { GlossaryToolFaqData } from "components/FAQ/config";
import { navigate } from "gatsby";
const GlossaryGenerator = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
   

  const { data } = useFetchLanguages();

  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);
  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      setTranslateFromInput(language);
      const languageName = Languages?.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      setTranslateToInput(languageTo);
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );

      if (languageToName?.name) {
        setOutputLang(languageTo);
      }
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "glossaryGenerator",
        source_text: inputText,
        translation: outputText,
        source_language_code: transtalteFromInput,
        target_language_code: transtalteToInput,
      });

      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(`/tools/glossary-generator/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;

  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv]);
  const handleSourceClearText = () => {
    setInputLang("");
    setInputText("");
    setWordCountInput(0);
  };
  const handleTargetClearText = () => {
    setOutputText("");
    setOutputLang("");
    setWordCountOutput(0);
  };

  return (
    <Layout>
      <SEO
        title="Bilingual Glossary"
        description="Generate bilingual glossaries instantly with our free AI-powered tool. It's the ideal solution for quickly creating glossaries tailored to your project."
        slug="/tools/glossary-generator"
      />
      <div className="bg-[#F5F5F5] ">
        <div className="w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
          <ToolsBanner
            text="Bilingual Glossary Generator"
            title="Generate a Bilingual Glossary for Consistent Translations"
            description="  Enter your source text and translation to generate a free bilingual glossary of key terms for your project. Terms such as proper names and street names that may not need to be translated will remain unchanged."
          />
          <div className="relative lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setInputLang={setInputLang}
                  setWordCount={setWordCountInput}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isEnterText={true}
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <p className=" text-[#6E6E6E] font-opensans font-bold">
                      Source text
                    </p>
                    {inputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleSourceClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                  </div>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={wordCountOutput}
                  setText={setOutputText}
                  setWordCount={setWordCountOutput}
                  refDiv={refDiv}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isTranslation={true}
                  isEnterText={true}
                >
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <p className=" text-[#6E6E6E] font-opensans font-bold">
                      Translated text
                    </p>
                    {outputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleTargetClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                  </div>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              //   handleReset={handleReset}
              showOutput={showOutput}
              error={error}
              ref={refDiv}
              divHeight={divHeight}
              isRefreshBtn={true}
              buttontext="Generate Glossary"
              text="Your results will appear here. <br /> Please enter your texts and click 'Generate Glossary' to begin."
            />
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Enter your source text and translation to generate a free bilingual
            glossary of key terms for your project. Terms such as proper names
            and street names that may not need to be translated will remain
            unchanged.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={GlossaryToolFaqData} />
    </Layout>
  );
};

export default GlossaryGenerator;
